@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@500&display=swap');

html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 3rem;
}
body {
height: 100%;
margin: 0;
position: relative;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif, 'Raleway', 'Montserrat', 'Outfit';
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
font-family: 'Montserrat';
}

p {
font-family: 'Montserrat';
margin: 0;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}